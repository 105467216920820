import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Layout from '../../../components/Layout/Layout';
import Seo from '../../../components/Seo/Seo';
import useIcons from '../../../hooks/useIcons';
import CollarLogo from '../../../images/collarLogo.svg';
import { clearLocalStorage } from '../../../utils';
import { BackImage, BookButton, BranchAddress, BranchBox, BranchContainer, BranchLogo, LogoContainer } from './styled';
import AnalyticsHeaders from './AnalyticsHeaders';
import { vars } from '../../../reactive';
import { FORM_PAGES } from '../../../components/Form/types';

function ProviderView({ pageContext }) {
  const { provider, branches } = pageContext;
  const { name } = provider;
  const { photos, colors } = branches[0];
  const icons = useIcons();
  const backIcon = getImage(icons?.arrowLeft)!;

  const resetPage = () => {
    vars.formPage(FORM_PAGES.PRODUCTS);
    clearLocalStorage();
  };

  return (
    <Layout>
      <BackImage to="../" onClick={resetPage}>
        <GatsbyImage image={backIcon} alt={'Back Icon'} />
      </BackImage>
      <BranchContainer primaryColor={colors.primary}>
        <BranchBox>
          <BranchLogo src={photos.logo} alt={name} />
          <BookButton to="book" onClick={resetPage}>
            Book Services
          </BookButton>
          {/* <BookButton to="brands" onClick={() => clearLocalStorage()}>
            See All Brands
          </BookButton> */}
          <LogoContainer>
            <Link to="https://getcollar.app/download" target="_blank" rel="noopener noreferrer">
              <CollarLogo />
            </Link>
          </LogoContainer>
        </BranchBox>
      </BranchContainer>
    </Layout>
  );
}

export default ProviderView;

export const Head = ({ pageContext, data }) => {
  const { branches, provider } = pageContext;
  const { name } = provider;
  const { photos, analytics } = branches[0];
  return (
    <Seo
      title={name}
      description={`Book services from ${name}, manage your pets data and connect with pet owners and businesses all while staying on top of your pets health and wellness.`}
      image={photos.logo}
    >
      <AnalyticsHeaders analytics={analytics} />
    </Seo>
  );
};
